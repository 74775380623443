import { useLocalStorage } from 'react-use';
import { useEffect } from 'react';
import { isNullOrUndefined } from '../../../utils/objectHelpers';

/**
 *  These are all the consent options supported by tag manager, so by tracking all of these 3rd parties will be able to
 *  only register themselves if the appropriate consent is given for their requirements.
 *  */
export type ConsentOptions = {
  ad_personalisation: boolean,
  ad_storage: boolean;
  ad_user_data: boolean,
  analytics_storage: boolean;
  // not in use, so always false
  functionality_storage: boolean;
  // not in use, so always false
  personalisation_storage: boolean;
  // not in use, so always false
  security_storage: boolean;
}
export const NO_CONSENT: ConsentOptions = {
  ad_personalisation: false,
  ad_user_data: false,
  ad_storage: false,
  analytics_storage: false,
  functionality_storage: false,
  personalisation_storage: false,
  security_storage: false
};
export const FULL_CONSENT: ConsentOptions = {
  ad_personalisation: true,
  ad_user_data: true,
  ad_storage: true,
  analytics_storage: true,
  functionality_storage: false,
  personalisation_storage: false,
  security_storage: false
};

const tryGetWindow = (): Window | null => {
  try {
    return window;
  } catch(e) {
    return null;
  }
}

export const useCookieConsent = () => {
  const consentSettings = useLocalStorage<ConsentOptions | null>('cookie_consent', null);

  const [consent, updateConsent] = consentSettings;
  const consentString = JSON.stringify(consent);
  const gtagIsReady = isNullOrUndefined(tryGetWindow()?.gtag);

  useEffect(() => {
    const windowRef = tryGetWindow();
    if (windowRef && windowRef.gtag && consent) {
      windowRef.gtag('consent', 'update', {
        ad_personalisation: consent?.ad_personalisation ? 'granted' : 'denied',
        ad_user_data: consent?.ad_user_data ? 'granted' : 'denied',
        ad_storage: consent?.ad_storage ? 'granted' : 'denied',
        analytics_storage: consent?.analytics_storage ? 'granted' : 'denied',
        functionality_storage: consent?.functionality_storage ? 'granted' : 'denied',
        personalisation_storage: consent?.personalisation_storage ? 'granted' : 'denied',
        security_storage: consent?.security_storage ? 'granted' : 'denied',
      });
        windowRef.gtag('event', 'consent_updated')
    }
  }, [consentString, gtagIsReady]);

  return consentSettings;
};