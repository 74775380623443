import { AlgoliaHit } from '../components/searching/Hit/hit.types';
import { capitalizeFirstLetter } from '../utils/helpers';
import { CanonicalItem, generateSizeDisplayString, generateTitleDisplayString } from './canonicalItem';
import { isNotNullOrUndefined } from '@apollographql/apollo-tools';

const extractSizeFromTags = (tags: string[]): CanonicalItem["size"] => {
  const rawSizes: Record<string, string> = tags
    .filter(tag => tag !== undefined)
    .map(tag => tag.trim().toLowerCase())
    .reduce((sizes, tag) => {
      const [aspect, measurement] = tag.split('_');

      return {
        ...sizes,
        [aspect.trim()]: (measurement.trim())
      }
    }, {});

  let sizes: CanonicalItem["size"] = { display: ''};

  if (rawSizes.height && rawSizes.width && rawSizes.length) {
    sizes.dimensions = {
      height: parseFloat(rawSizes.height),
      width: parseFloat(rawSizes.width),
      length: parseFloat(rawSizes.length),
    }
  }

  const sizeMappings: Record<string, keyof CanonicalItem["size"]> = {
    shoeuk: 'uk_shoe',
    shoeeu: 'eu_shoe',
    uk: 'uk',
    waist: 'waist',
    leg: 'leg',
    collar: 'collar',
    chest: 'chest',
    sml: 'sml'
  };

  Object.entries(rawSizes).forEach(([key, value]) => {
    // @ts-ignore
    sizeMappings[key] && (sizes[sizeMappings[key]] =
      key === 'sml' ? value.toUpperCase() : parseFloat(value));
  });


  return sizes;
}

const extractSizeFromOldStyleTags = (tags: string[]): CanonicalItem["size"] => {
  const rawSizes: Record<string, string> = tags
    .filter(tag => tag !== undefined)
    .filter(tag => tag.startsWith('Size'))
    .map(tag => tag.trim().toLowerCase().slice('size'.length))
    .reduce((sizes, tag) => {
      const [aspect, measurement] = tag.split('_');
      if (measurement.trim().length === 0) return sizes;
      return {
        ...sizes,
        [aspect.trim()]: (measurement.trim())
      }
    }, {});

  let sizes: CanonicalItem["size"] = { display: ''};

  if (rawSizes.height && rawSizes.width && rawSizes.length) {
    sizes.dimensions = {
      height: parseFloat(rawSizes.height),
      width: parseFloat(rawSizes.width),
      length: parseFloat(rawSizes.length),
    }
  }

  const sizeMappings: Record<string, keyof CanonicalItem["size"]> = {
    shoeuk: 'uk_shoe',
    shoeeu: 'eu_shoe',
    uk: 'uk',
    waist: 'waist',
    leg: 'leg',
    collar: 'collar',
    chest: 'chest',
    sml: 'sml'
  };

  Object.entries(rawSizes).forEach(([key, value]) => {
    // @ts-ignore
    sizeMappings[key] && (sizes[sizeMappings[key]] =
      key === 'sml' ? value.toUpperCase() : parseFloat(value));
  });


  return sizes;
}

const tryOr = <T>(fn: () => T, fallback: T) => {
  try {
    return fn();
  } catch (_e) {
    return fallback;
  }
}



export const convertAlgoliaHitToCanonicalItem = (hit: AlgoliaHit): CanonicalItem => {
  const getOldTag = (tagPrefix: string) => {
    const fullPrefix = `${tagPrefix}_`
    const tag = hit.tags
      .find((tag) => tag.startsWith(fullPrefix));
    if (!tag) return null;
    return tag.slice(fullPrefix.length);
  }
  const getNamedTag = (name: keyof AlgoliaHit['named_tags']) => {
    return isNotNullOrUndefined(hit.named_tags[name]) ? `${hit.named_tags[name]}` : null
  }

  const tryGetTag = (namedTag: keyof AlgoliaHit['named_tags'], fallbackTag: string, fallback: string) => {
    return (getNamedTag(namedTag) ?? getOldTag(fallbackTag) ?? fallback)
  }

  const brand = tryGetTag('brand', 'Brand', '');
  const brand_type = tryGetTag('brand_type', 'BrandType', '');

  let sizeTags = (Array.isArray(hit.named_tags.size) ? hit.named_tags.size : [hit.named_tags.size]).filter(isNotNullOrUndefined);
  const size = extractSizeFromTags(sizeTags);
  const fallbackSize = extractSizeFromOldStyleTags(hit.tags);

  const canonicalItem: CanonicalItem = {
    id: hit.id,
    title: hit.title,
    display_title: '',
    handle: hit.handle,
    photos: [hit.image],
    department: hit.named_tags.department,
    brand: ['unknown', 'unbranded'].includes(brand.toLowerCase()) ? undefined : brand,
    brand_type: brand_type,
    colour: tryGetTag('colour', 'Colour', ''),
    condition: tryGetTag('condition', 'ConditionWebsite', '').split('_').join(' '),
    style: tryGetTag('style', 'Style', ''),
    category: tryGetTag('category', 'Category', ''),
    size: Object.keys(size).length > 1 ? size : fallbackSize, // always a 'display' size, even if blank string, so check greater than 1
    seller: hit.named_tags.seller,
    collections: hit.collections,
    materials: hit.tags
      .filter((tag) => tag.startsWith('Material_'))
      .map((tag) => tag.toLowerCase().replace('material_', '').replace(/_/g, ''))
      .flatMap(tag => tag.split('-'))
      .map(material => material.trim())
      .map(capitalizeFirstLetter),
    original_tags: hit.named_tags.original_tags ?? false,
    total_inventory: hit.variants_inventory_count ?? 0,
    in_stock:  hit.variants_inventory_count > 0,
    defects: hit.tags
      .filter((tag) => tag.indexOf('Defect') > -1)
      .map((tag) => tag.toLowerCase().replace('defect_', '').replace(/_/g, ' '))
      .map(capitalizeFirstLetter),
    sku: hit.sku,
    strikethrough_price: hit.compare_at_price,
    variant_id: parseInt(`${hit.objectID}`),
    price:  hit.price,
    pattern: hit.named_tags.pattern,
    neckline: hit.named_tags.neckline,
    sleeve_length: hit.named_tags.sleeve_length,
    dress_length: hit.named_tags.dress_length,
    leg_style: hit.named_tags.leg_style,
    listed_at: tryOr(() => {
      if (isNotNullOrUndefined(hit.named_tags.listed_at)) return new Date(hit.named_tags.listed_at).toISOString();
      const uploadDate = getOldTag('UploadDate')
      if (isNotNullOrUndefined(uploadDate)) return new Date(uploadDate).toISOString();
      return null;
    }, null),
    _original: hit
  };
  canonicalItem.size.display = generateSizeDisplayString(canonicalItem);
  canonicalItem.display_title = generateTitleDisplayString(canonicalItem);


  const canonicalItemWithUndefinedValuesRemoved = JSON.parse(JSON.stringify(canonicalItem));

  console.log({canonicalItemWithUndefinedValuesRemoved});
  return canonicalItemWithUndefinedValuesRemoved;
}