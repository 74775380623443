import type { AppContext, AppProps } from 'next/app';
import '../styles/fonts.css';
import '../styles/base.css';
import '../styles/globals.css';

import { EnvUtility } from '../helpers/env.utility';
import { AnalyticUtility } from '../helpers/analytic.utility';

import { Layout } from '../components/layouts/layout';

import { storyInit } from '../modules/@storyblok/storyblok';

import { SessionProvider } from '../context/SessionContext';
import { DrawerProvider } from '../context/DrawerContext';
import { CheckoutCartProvider } from '../context/CheckoutCartContext';
import { WebviewEventContextProvider } from '../context/WebviewEventContext';
import { CookiesProvider } from 'react-cookie';
import { useStoryblokState, getStoryblokApi, ISbStoryData, ISbStoriesParams } from '@storyblok/react';
import { AccountProvider } from '../context/AccountContext';
import { WishlistProvider } from '../context/WishlistContext';
import { DefaultSeo, DefaultSeoProps } from 'next-seo';
import { ConfigStoryblok } from '../component-types-sb';
import Script from 'next/script';
import { ModalProvider } from '../context/ModalContext';
import { ReactQueryProvider } from '../context/QueryContext';
import { Toaster } from 'react-hot-toast';
import { ConfigContextProvider } from '../context/ConfigContext';


interface App extends AppProps {
  data: ISbStoryData<ConfigStoryblok>;
  preview?: boolean;
}

storyInit(); // Initializes StoryBlok

function MyApp({ Component, pageProps, data, preview }: App) {
  const config = useStoryblokState(data, {});

  AnalyticUtility.useTracker();

  const defaultSEOSettings: DefaultSeoProps = {
    title: config?.content.seo_default_title,
    description: config?.content.seo_default_description,
    openGraph: {
      site_name: 'Thrift+',
      type: 'website',
      images: [
        {
          url: config?.content.seo_default_image?.filename ?? '',
          width: 1200,
          height: 628,
          alt: config?.content.seo_default_image?.alt,
        },
      ],
    },
    twitter: {
      cardType: 'summary_large_image',
    },
  };

  return (
    <ConfigContextProvider config={data}>
      <CookiesProvider>
        <ReactQueryProvider>
          <DrawerProvider>
            <ModalProvider>
              <CheckoutCartProvider config={data}>
                <SessionProvider>
                  <WishlistProvider>
                    <AccountProvider>
                      <WebviewEventContextProvider config={data}>
                        <Layout config={data}>
                          <DefaultSeo {...defaultSEOSettings} />
                          <Component {...pageProps} />

                          <Script id="google-tag-manager" strategy="afterInteractive">
                            {`  
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
    
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalisation': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage': 'denied',
        'personalisation_storage': 'denied',
        'security_storage': 'denied',
      });
    
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
          `}
                          </Script>
                          <div className="sweettooth-launcher"></div>
                        </Layout>

                        <Script async src="https://cdn.sweettooth.io/assets/storefront.js" />

                        <div
                          className="sweettooth-init"
                          data-channel-api-key="channel_6p6GEDSwxYxtCJLglD1Z3zDc"
                          data-external-customer-id=""
                          data-customer-auth-digest=""
                        ></div>
                        <Script
                          async
                          type="text/javascript"
                          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=PGY4Zz"
                        />

                        <Script id="klaviyo-starter">{`var _learnq = _learnq || [];`}</Script>
                        <Script id="gtm-extra">{`if (window.ReactNativeWebView) { var dl = dataLayer || []; dl.push({'campaign_medium': "thrift-app"}); }`}</Script>
                      </WebviewEventContextProvider>
                    </AccountProvider>
                  </WishlistProvider>
                </SessionProvider>
              </CheckoutCartProvider>
            </ModalProvider>
          </DrawerProvider>
          <Toaster position={'top-center'}/>
        </ReactQueryProvider>
      </CookiesProvider>
    </ConfigContextProvider>
  );
}

MyApp.getInitialProps = async ({ router }: AppContext) => {
  const storyblokApi = getStoryblokApi();

  let sbParams: ISbStoriesParams = {
    // load the draft version
    version: 'published', // or 'published'
  };

  if (router.isPreview) {
    sbParams.version = 'draft';
  }

  let { data } = await storyblokApi.get('cdn/stories/config', sbParams);

  return { data: data.story, preview: router.isPreview };
};

export default MyApp;
