import React, { useCallback, useState } from 'react';
import { Button, Image } from '../../core';

import styles from './styles.module.css';
import { HeartWishlistIcon, HeartWishlistIconRemove } from '../../core/Icons/iconList';
import { useContext } from 'react';
import { WishlistContext } from '../../../context/WishlistContext';
import { AlgoliaHit } from './hit.types';
import { IntlUtility } from '../../../helpers';
import classNames from 'classnames';
import { Tracking } from '../../../utils/tracking';
import Link from 'next/link';
import { getSessionStorageValue, setSessionStorageValue } from '../../../utils/sessionStorage';
import { Element } from 'react-scroll';
import { useItemWatchCount } from '../../../data-fetching/useItemWatchCount';
import { convertAlgoliaHitToCanonicalItem } from '../../../data-fetching/useAlgoliaHit';
import { toShortTitle } from '../../../utils/formatting';
import { algoliaInsights } from '../insights';
import { ProductSearchState } from '../queries/types';
import { algoliaIndices } from '../../../utils/constants';
import { IntersectionSideEffects } from '../../util/IntersectionSideEffects';
import { useCookieConsent } from '../../sections/CookieConsent/useCookieConsent';
import { useItemCallouts } from '../useItemCallouts';

import { useCartLine } from '../../../data-fetching/cart/useCartLine';
import { PriceRenderer } from '../../shopify/SimpleItemCard';

type HitProps = {
  hit: AlgoliaHit;
  collectionId?: string;
  collectionName?: string;
  prevURL?: string;
  searchState?: ProductSearchState;
};

const Hit: React.FC<HitProps> = ({
  hit,
  collectionId,
  collectionName,
  prevURL,
  searchState,
}) => {
  const [cookieConsent] = useCookieConsent();
  const item = convertAlgoliaHitToCanonicalItem(hit);
  const [loading, setLoading] = useState(false);
  //TODO: should this be in the parent to prevent everything from rerendering
  const { toggleItemInWishlist, itemIsInWishlist } = useContext(WishlistContext);
  const { isInCart, addToCart } = useCartLine(item.variant_id);

  const isInWishlist = itemIsInWishlist(item);
  const isOutOfStock = !item.in_stock;

  const watchCount = useItemWatchCount(item.id) ?? 0;

  const handleAddToCart = async () => {
    if (!addToCart) return;
    setLoading(true);
    await addToCart();
    if (cookieConsent?.analytics_storage) {
      // need consent to do the tracking
      Tracking.AddToCart(item);
      algoliaInsights('addedToCartObjectIDsAfterSearch', {
        //Triggers algolia insights conversion event
        index: searchState?.sort ?? algoliaIndices.shopifyProducts,
        eventName: 'Product Added to Cart',
        queryID: hit.__queryID,
        objectIDs: [hit.objectID],
        currency: "GBP",
        value: item.price,
        objectData:[{
          price: item.price,
          discount: item.strikethrough_price ? (item.strikethrough_price - item.price).toFixed(2) : undefined,
          quantity: 1,
        }]
      });
    }
    setLoading(false);
  };

  const storePrevious = () => {
    // this is for breadcrumbs, so we don't need consent as it's just functionality
    const current = getSessionStorageValue('productsPrev');
    const updated = { ...current, [item.id]: prevURL };
    setSessionStorageValue('productsPrev', updated);
  };

  const storeProductHitIds = () => {
    if (cookieConsent?.analytics_storage) {
      // this is for tracking product hits and algolia optimisation, so we need consent
      const current = getSessionStorageValue('productHitIds');
      const updated = { ...current, [item.id]: { queryId: hit.__queryID, objectId: hit.objectID } };
      setSessionStorageValue('productHitIds', updated);
    }
  };

  const onView = useCallback(() => {
    if (cookieConsent?.analytics_storage) {
      algoliaInsights('viewedObjectIDs', {
        //Triggers algolia insights conversion event
        index: searchState?.sort ?? algoliaIndices.shopifyProducts,
        eventName: 'Hits Viewed',
        objectIDs: [hit.objectID],
      });
    }
  }, [])

  const promoCallouts = useItemCallouts(item);
  const leftCallout = promoCallouts.find(callout => callout.sidePreference === 'left');
  const rightCallout = promoCallouts.find(callout => callout.sidePreference === 'right');

  return (
    <IntersectionSideEffects onIntersection={onView}>
      <Element name={item.title}>
        <Link
          href={`/products/${item.handle}`}
          data-insights-object-id={hit.objectID}
          data-insights-position={hit.__position}
          data-insights-query-id={hit.__queryID}
          onClick={() => {
            if (cookieConsent?.analytics_storage) {
              if (collectionId && collectionName) {
                Tracking.SelectProductInList(collectionId, collectionName, [Tracking.convertCanonicalItemToAnalytics(item)]);
              }
              if (hit) {
                algoliaInsights('clickedObjectIDsAfterSearch', {
                  //Triggers algolia insights conversion event
                  index: searchState?.sort ?? algoliaIndices.shopifyProducts,
                  eventName: 'Product Clicked',
                  queryID: hit.__queryID,
                  objectIDs: [hit.objectID],
                  positions: [hit.__position],
                });
              } //Triggers algolia insights conversion event
              storeProductHitIds();
            }
            storePrevious();
            setSessionStorageValue('prevViewedHit', item.title); // not sure if we use this any more?
          }}
        >
          <div className={styles.container}>
            <div className={classNames(styles.imageContainer)}>
              <Image
                src={item.photos[0] ?? ''}
                alt={item.title}
                loading="lazy"
                width={300}
                height={400}
                className={styles.image}
              />
            </div>
            <div className={styles.badgeWrapper}>
              {leftCallout && (
                <div className={`${styles.badge} ${leftCallout.colour === 'blackAlt' ? 'text-white' : 'text-blackAlt'} bg-${leftCallout.colour} ${rightCallout ? "left" : "right"}-0`}>
                  {leftCallout.label}
                </div>
              )}
              {rightCallout && (
                <div className={`${styles.badge} ${rightCallout.colour === 'blackAlt' ? 'text-white' : 'text-blackAlt'} bg-${rightCallout.colour} right-0`}>
                  {rightCallout.label}
                </div>
              )}
            </div>
            <div className={styles.actions}>
              <Button
                className={styles.addToCart}
                onClick={async (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await handleAddToCart();
                }}
                isLoading={loading}
                text={isInCart ? 'Added to Bag' : isOutOfStock ? 'Sold out' : 'Add to bag'}
                loadingText="Adding to bag"
                disabled={isInCart || loading || isOutOfStock || !addToCart}
              />
              <div
                className={styles.wishlist}
                onClick={async (ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  toggleItemInWishlist(item);
                }}
              >
                <div className="flex items-center justify-center mr-2 mb-2">
                  {watchCount > 3 && (<span className="body-14-bold">{watchCount} </span>)}
                  <span className="px-1 py-1">{isInWishlist ? <HeartWishlistIconRemove title="heart" /> : <HeartWishlistIcon title="heart" />}</span>
                </div>
              </div>
            </div>
          </div>
          <h3 className={styles.title}>{toShortTitle(item)}</h3>
          <p className={styles.description}>{item.size.display}</p>
          <PriceRenderer item={item} />
        </Link>
      </Element>
    </IntersectionSideEffects>
  );
};



export default Hit;