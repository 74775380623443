import { useContext, useMemo, useRef, useEffect } from "react";
import { getWishlistItemHandle, WishlistContext } from '../../../../context/WishlistContext';
import { WatchlistStoryblok } from "./types";
import { IndependentWishlistItemCard } from "../Drawer/Wishlist/List";
import styles from './styles.module.css';
import classNames from "classnames";
import { Signpost } from "../../../core";
import { Skeleton } from "../../../account/Common/Skeleton";
import { useScrollbar } from "./useScrollbar";
import { SwymList } from '../../../../modules/@swym/swym.service';
import { isNullOrUndefined } from '../../../../utils/objectHelpers';

interface Props {
    blok: WatchlistStoryblok;
}

const firstFiveWishlistItemHandles = (wishlist?: SwymList): string[] | null => {
    if (isNullOrUndefined(wishlist)) return null;
    if (wishlist?.listcontents.length < 5) return null;
    return wishlist.listcontents.slice(0, 5)
      .map((item) => getWishlistItemHandle(item))
}

export const Watchlist = ({ blok }: Props) => {
    const { wishlist, wishlistLoading } = useContext(WishlistContext);
    const { text } = blok || {};
    const { scrollbarRef, scrollbarSliderRef } = useScrollbar();

    const firstFiveItems = firstFiveWishlistItemHandles(wishlist)
    if (!firstFiveItems) return null;

    return (
        <section
        className={classNames('Section')}
        >
            {wishlistLoading ?
            <div className="flex flex-row mr-5 mt-13 justify-center items-center gap-12">
                <Skeleton height={280} width={600} />
                <Skeleton height={280} width={600} />
                <Skeleton height={280} width={600} />
                <Skeleton height={270} width={600} />
                <Skeleton height={270} width={600} />
            </div> :
            <div className={styles.container}>
                {text && <h3 className={classNames(styles.title, 'display-18-caps')}>{text}</h3>}
                <div className="relative">
                    <div className={styles.products} ref={scrollbarRef} >
                        {firstFiveItems.map((itemHandle) => {
                        return <IndependentWishlistItemCard key={itemHandle} handle={itemHandle}
                        className={styles.card} />;
                        })}
                    </div>
                    <div className={styles.slider}>
                        <div ref={scrollbarSliderRef} className={styles.sliderHandle} />
                    </div>
                </div>
                <div className="flex relative justify-end mb-2 mt-2 md:mt-0 md:mb-4">
                    <Signpost href="/wishlist" signpostText="See all"></Signpost>
                </div>
            </div>}
      </section>
    )
}