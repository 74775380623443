export const sizeFilterAttributes = [
  { label: 'UK Clothing Size', value: 'uk' },
  { label: 'SML Clothing Size', value: 'sml' },
  { label: 'UK Shoe Size', value: 'shoeuk' },
  { label: 'US Shoe Size', value: 'shoeus' },
  { label: 'Waist', value: 'waist' },
  { label: 'Leg Length', value: 'leg' },
  { label: 'Chest', value: 'chest' },
  { label: 'Collar', value: 'collar' },
];

export const uppercaseSizeAttributes = ['uk', 'us', 'eu'];

export const hiddenSizeAttributes = ['shoeuk', 'shoeus', 'sml'];

export const filterAttributes = [
  { label: 'size', value: 'named_tags.size', hasSubAttributes: true },
  { label: 'category', value: 'named_tags.category' },
  { label: 'style', value: 'named_tags.style' },
  { label: 'brand', value: 'named_tags.brand' },
  { label: 'brand type', value: 'named_tags.brand_type' },
  { label: 'colour', value: 'named_tags.colour' },
  { label: 'condition', value: 'named_tags.condition' },
  { label: 'material', value: 'named_tags.material' },
  { label: 'price range', value: 'price_range' },
  { label: 'tags attached', value: 'named_tags.original_tags'},
  { label: 'tags', value: 'tags' },
];

export const searchableAttributeValues = ['named_tags.brand', 'named_tags.style'];

export const genderDepartmentTags = {
  men: "Department_Men's",
  women: "Department_Women's",
};

export const genderDepartmentTagValues = {
  men: "Men's",
  women: "Women's",
};

export const algoliaIndices = {
  // default index shopify_products was set up to sort loosely by newest (after relevance) - replicas employ other sorting strategies
  shopifyProducts: 'shopify_products',
  shopifyProductsNewest: 'shopify_products_processed_at_desc',
  shopifyCollectionsQuerySuggestions: 'shopify_collections_query_suggestions',
  shopifyProductsPriceAsc: 'shopify_products_price_asc',
  // shopifyProductsPriceAsc: 'cat_sorting_by_price_asc',
  shopifyProductsPriceDesc: 'shopify_products_price_desc',
  shopifyProductsPersonalisationTests: 'shopify_products_relevance_personalised',
};

export const bgColorTextToValue = {
  grey: 'rgba(221, 215, 220, 0.5)',
  yellow: 'rgba(237, 225, 84, 0.25)',
  green: 'rgba(147, 193, 178, 0.25)',
  transparent: 'transparent',
  black: 'black'
};

export const descriptionTags = {
  style: 'style',
  // material: 'material', - commenting this out so material can use the old shopify tags (with secondary materials included)
  colour: 'colour',
  neckline: 'neckline',
  sleevelength: 'sleeve length',
  dresslength: 'dress length',
  legstyle: 'leg style',
  condition: 'condition',
  original_tags: 'original_tags',
  care: 'care',
  dimensions: 'dimensions',
  sku: 'sku',
};

export const thriftBagVariantID = 'gid://shopify/ProductVariant/29937525981270';

export const thriftBagVariantIDs = [
  'gid://shopify/ProductVariant/29937525981270',
  'gid://shopify/ProductVariant/40613512773822',
  'gid://shopify/ProductVariant/41479848820926',
  'gid://shopify/ProductVariant/30345124380758',
];

export const hiddenHeaderURLs = ['/get-started-', '-reloved-collection', 'wwf-collection', 'white-stuff-pre-loved-store'];

export const validFilterKeys = ['uk', 'sml', 'shoeuk', 'shoeus', 'waist', 'leg', 'chest', 'collar', 'size', 'category', 'style', 'brand', 'brand_type', 'colour', 'condition', 'material', 'price_range', 'tags']
export const namedTagsFilterKeys = ['size', 'category', 'style', 'brand', 'brand_type', 'colour', 'condition', 'material']